import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link, useHistory, useParams } from 'react-router-dom'

import { useQueryParams } from '@procsea/common/hooks'
import { Button, GlobalStyle, Input, Stack, Text } from '@procsea/design-system'

import FullscreenLoader from 'src/components/base/fullscreenLoader/FullscreenLoader'
import { useAuth } from 'src/contexts/authContext'
import useDocumentTitle from 'src/hooks/useDocumentTitle'
import klarysLogo from 'src/images/klarys-logo.svg'
import lock from 'src/images/lock.svg'
import { LocaleParams, ResetPasswordField, ResetPasswordForm } from 'src/types'

import { AuthContainer, AuthMainCard, AuthMainCardImage, HeaderLogo } from '../common'

enum TokenError {
  OUTDATED = 'outdated',
  UNDEFINED = 'undefined',
}

const ResetPassword = () => {
  useDocumentTitle(gettext('Reset Password'))

  const auth = useAuth()
  const { control, errors, handleSubmit, watch } = useForm<ResetPasswordForm>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })
  const history = useHistory()
  const { locale } = useParams<LocaleParams>()
  const queryParams = useQueryParams()
  const [token, setToken] = useState<string>()
  const [isValidatingToken, setIsValidatingToken] = useState(true)
  const [tokenError, setTokenError] = useState<TokenError>()
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [apiError, setApiError] = useState<string>()
  const [passwordApiError, setPasswordApiError] = useState<string>()

  const currentPassword = watch(ResetPasswordField.PASSWORD)

  const onSubmit = async (form: ResetPasswordForm) => {
    setIsLoading(true)
    const response = await auth.resetPassword({
      password: form[ResetPasswordField.PASSWORD],
      token: form[ResetPasswordField.TOKEN],
    })
    if (!response.ok) {
      const { user_message: userMessage, validation_errors: validationErrors } =
        await response.json()
      if (validationErrors?.password) {
        setPasswordApiError(validationErrors.password)
      } else {
        setApiError(userMessage)
      }
    } else {
      history.push(`/${locale}/accounts/reset/done`)
    }
    setIsLoading(false)
  }

  const validateToken = async () => {
    if (token) {
      const response = await auth.validatePasswordResetToken({ token })
      if (!response.ok) {
        setTokenError(TokenError.OUTDATED)
      }
      setIsValidatingToken(false)
    }
  }

  useEffect(() => {
    const queryToken = queryParams.get('token')
    if (queryToken) {
      setToken(queryToken)
    } else {
      setTokenError(TokenError.UNDEFINED)
      setIsValidatingToken(false)
    }
  }, [])

  useEffect(() => {
    validateToken()
  }, [token])

  return isValidatingToken ? (
    <FullscreenLoader text={gettext('Checking reset link validity')} />
  ) : (
    <AuthContainer>
      <GlobalStyle styledTheme="light" />
      <a data-e2e="login-home-link" href={window.WEBSITE_URL || '/'}>
        <HeaderLogo alt="Klarys logo" src={klarysLogo} />
      </a>
      <AuthMainCard shadow="middle" spacing="xlarge">
        <Stack spacing="large">
          <AuthMainCardImage src={lock} />
          <Text size="xxxlarge" variant="secondary">
            {gettext('Pick a new password')}
          </Text>

          {tokenError === TokenError.UNDEFINED && (
            <>
              <Text variant="black">
                {gettext(
                  'Sorry, this link is not valid, please click below to restart the password reset procedure.'
                )}
              </Text>
              <Link to={`/${locale}/accounts/password_reset`}>
                <Button fluid variant="primary">
                  {gettext('Forgot password')}
                </Button>
              </Link>
            </>
          )}
          {tokenError === TokenError.OUTDATED && (
            <>
              <Text variant="black">
                {gettext(
                  'Sorry, this link is no longer valid, please click below to restart the password reset procedure.'
                )}
              </Text>
              <Link to={`/${locale}/accounts/password_reset`}>
                <Button fluid variant="primary">
                  {gettext('Forgot password')}
                </Button>
              </Link>
            </>
          )}
          {!tokenError && !isValidatingToken && (
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Controller
                hidden
                as={<input />}
                control={control}
                defaultValue={token || ''}
                name={ResetPasswordField.TOKEN}
              />
              <Stack spacing="large">
                <Controller
                  control={control}
                  defaultValue=""
                  name={ResetPasswordField.PASSWORD}
                  render={controllerProps => (
                    <Input
                      fluid
                      data-e2e="reset-password-password-input"
                      disabled={isLoading}
                      errorMessage={
                        passwordApiError || errors[ResetPasswordField.PASSWORD]?.message
                      }
                      hasError={!!passwordApiError || !!errors[ResetPasswordField.PASSWORD]}
                      icon={isPasswordVisible ? 'eye' : 'eye-slash'}
                      label={gettext('Create your new password')}
                      name={ResetPasswordField.PASSWORD}
                      onBlur={controllerProps.onBlur}
                      onChange={({ value }: { value: string }) => {
                        controllerProps.onChange(value)
                        setApiError(undefined)
                      }}
                      onIconClick={() => setIsPasswordVisible(oldValue => !oldValue)}
                      type={isPasswordVisible ? 'text' : 'password'}
                      value={controllerProps.value}
                    />
                  )}
                  rules={{
                    minLength: {
                      message: gettext(
                        'This password is too short, it must contain at least 8 characters'
                      ),
                      value: 8,
                    },
                    required: gettext('This field is required'),
                  }}
                />

                <Controller
                  control={control}
                  defaultValue=""
                  name={ResetPasswordField.PASSWORD_VALIDATE}
                  render={controllerProps => (
                    <Input
                      fluid
                      data-e2e="reset-password-password-validate-input"
                      disabled={isLoading}
                      errorMessage={
                        passwordApiError || errors[ResetPasswordField.PASSWORD_VALIDATE]?.message
                      }
                      hasError={
                        !!passwordApiError || !!errors[ResetPasswordField.PASSWORD_VALIDATE]
                      }
                      label={gettext('Re type your password')}
                      name={ResetPasswordField.PASSWORD_VALIDATE}
                      onBlur={controllerProps.onBlur}
                      onChange={({ value }: { value: string }) => {
                        controllerProps.onChange(value)
                        setApiError(undefined)
                      }}
                      type={isPasswordVisible ? 'text' : 'password'}
                      value={controllerProps.value}
                    />
                  )}
                  rules={{
                    validate: value =>
                      value === currentPassword || gettext('The passwords do not match'),
                    required: gettext('This field is required'),
                  }}
                />

                {!!apiError && <Text variant="danger">{apiError}</Text>}

                <Button
                  data-e2e="reset-password-submit-button"
                  disabled={isLoading}
                  type="submit"
                  variant="primary"
                >
                  {gettext('Reset password')}
                </Button>
              </Stack>
            </form>
          )}
        </Stack>
      </AuthMainCard>
    </AuthContainer>
  )
}

export default ResetPassword
